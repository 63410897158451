<template>
  <q-page class="productListing">
    <template v-if="mount && !loading">
      <CollectionHeader
        v-if="collection"
        :collection="collection"
        :sortVal="collectionFilters.sortBy"
        @sorby-modified="sortByModified"
        @changeView="changeView"
      />
      <Listing
        v-if="collection"
        :collectionId="collection ? collection.id : null"
        productsBy="CL"
        :sortBy="collectionFilters.sortBy"
        :pageSize="collectionFilters.pageSize"
        :isListView="!isMobile ? collectionFilters.isListView : false"
        @sorby-modified="sortByModified"
      />
      <EmptyComponent
        v-else
        image="images/empty-product.png"
        btnText="Continue Shopping"
        to="/"
      >
        <p>
          Currently, this collection is not available for the selected location.
          Please select another location.
        </p>
      </EmptyComponent>
    </template>
    <ProductListingSkeleton
      v-else
      :showHeaderLoader="true"
      :showFilterBarLoader="true"
      :showListingLoader="true"
      :isListView="!isMobile ? collectionFilters.isListView : false"
    />
  </q-page>
</template>

<script>
import CollectionHeader from 'components/collection/CollectionHeader'
import Listing from 'components/product/Listing'
import { mapGetters } from 'vuex'
import {
  setAVSessionID,
  removeAVSessionID,
  tempCacheStorage,
} from 'src/boot/axios'
import { pageSize } from 'src/store/discounts/getters'

export default {
  name: 'CollectionPage',
  /* preFetch({ store, currentRoute, redirect }) {
    let { seoName } = currentRoute.params,
      promise

    promise = store
      .dispatch('collection/getCollection', seoName)
      .then((collection) => {
        // if (!collection) store.commit('collection/SET_LOADING', false)
        // redirect({
        //   name: 'Home',
        // })
      })

    if (process.env.SERVER) return promise
  }, */
  props: {
    seoName: {
      type: String,
      default: '',
    },
  },
  meta() {
    return {
      title: this.title,
      ...this.commonMeta,
    }
  },
  data() {
    return {
      mount: false,
    }
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)

    // Browse the products on successful verification
    /* if (
      !this.isCordova &&
      !this.showEAIVPage &&
      this.businessConfigurations?.isStoreAgeRestricted &&
      this.businessConfigurations?.enableAgeRestrictedBrowsing &&
      !this.loading &&
      !this.collection?.title
    ) {
      this.$root.$emit('scroll-to-position')
      this.$store
        .dispatch('collection/getCollection', this.$route.params.seoName)
        .then((collection) => {
          if (!collection)
            this.$router.push({
              name: 'Home',
            })
        })
    } */
  },
  computed: {
    title() {
      return this.collection ? this.collection.title || '' : ''
    },
    ...mapGetters('collection', ['collection', 'loading']),
    ...mapGetters('product', ['pagination']),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
      // 'showEAIVPage',
    ]),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    ...mapGetters('ageVerification', [
      'isVerificationFail',
      'showVeirificationPopup',
    ]),
    ...mapGetters('customer', ['customer']),
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
    collectionFilters() {
      return {
        ...this.getFilterByPage('collection'),
        pageSize: 20,
      }
    },
  },
  components: {
    CollectionHeader,
    Listing,
    ProductListingSkeleton: () => import('components/ProductListingSkeleton'),
  },
  destroyed() {
    this.$store.commit('collection/SET_COLLECTION', null)
    this.$store.commit('product/RESET_PAGINATION')
  },
  methods: {
    changeView(isListView) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'collection',
        filter: {
          ...this.collectionFilters,
          isListView,
        },
      })
    },
    sortByModified(sortBy) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'collection',
        filter: {
          ...this.collectionFilters,
          sortBy,
        },
      })
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (isSuccess) setAVSessionID(eaivSessionId)
      else {
        this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
        removeAVSessionID()
      }

      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params })
    },
    onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Collection') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, true)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
        }, 500)

        if (this.isCordova) {
          this.$root.$emit('scroll-to-position')
          this.$store
            .dispatch('collection/getCollection', this.$route.params.seoName)
            .then((collection) => {
              if (!collection)
                this.$router.push({
                  name: 'Home',
                })
            })
        } else if (isPopup) {
          tempCacheStorage.clear()
          this.$root.$emit('scroll-to-position')
          this.$store
            .dispatch('collection/getCollection', this.$route.params.seoName)
            .then((collection) => {
              if (!collection)
                this.$router.push({
                  name: 'Home',
                })
            })
        }
      }
    },
    onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Collection') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {},
          })
        }, 500)

        if (this.isCordova) {
          this.$root.$emit('scroll-to-position')
          this.$store
            .dispatch('collection/getCollection', this.$route.params.seoName)
            .then((collection) => {
              if (!collection)
                this.$router.push({
                  name: 'Home',
                })
            })
        } else if (isPopup) {
          tempCacheStorage.clear()
          this.$root.$emit('scroll-to-position')
          this.$store
            .dispatch('collection/getCollection', this.$route.params.seoName)
            .then((collection) => {
              if (!collection)
                this.$router.push({
                  name: 'Home',
                })
            })
        }
      }
    },
  },
  mounted() {
    this.mount = true
    // prefetch code
    let { seoName } = this.$route.params

    this.$store
      .dispatch('collection/getCollection', seoName)
      .then((collection) => {
        if (!collection) this.$store.commit('collection/SET_LOADING', false)
      })
    // prefetch code
  },
}
</script>

<style lang="scss"></style>
